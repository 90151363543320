import { PushLogOptions } from '@grafana/faro-react'
import { SyncedGameEvents } from '../../hooks/useGameDb'
import {
  GameScoringStatus,
  ScoringRoles,
  SyncStatus,
} from '../../providers/LiveScoringProvider/LiveScoringProvider.types'
import {
  GamePutPayload,
  GameToPublish,
  PublishedGameStatus,
} from '../GameDatastoreContext/GameDatastoreContext'
import { IdbLastUpdated, IdbLocallyScoredGame } from './idb/Idb.types'

export interface Datastore {
  getOrCreateInstance: (
    gameID: string,
  ) => GameDatastore | Promise<GameDatastore>
}

export interface GameDatastore {
  putEvent: (payload: GamePutPayload['data']) => Promise<SyncedGameEvents[]>
  setEventLog: (events: SyncedGameEvents[]) => Promise<void>
  getEventLog: () => Promise<SyncedGameEvents[]>
  getLocallyScoredGame: () => Promise<IdbLocallyScoredGame | undefined>
  setSyncStatusForEvents: (
    items: Array<{ id: string; status: SyncStatus }>,
  ) => Promise<void>
  updatePublishedGameStatus: (status: PublishedGameStatus) => Promise<void>
  updateLocallyScoredGame: (
    game: Partial<IdbLocallyScoredGame>,
  ) => Promise<void>
  getLastUpdated: () => Promise<IdbLastUpdated | undefined>
}

export interface GameSyncingWorkerInitLiveScoringEvent {
  type: 'INIT'
  payload: {
    games: string[]
    websocketURL: string | undefined
    venueID: string
    authInfo: {
      sessionID: string
      idToken: string
      lastAuthUser: string
      refreshToken: string
      keyPrefix: string
    }
  }
}

export interface GameSyncingWorkerAddEventsMessage {
  type: 'ADD_EVENT'
  payload: GamePutPayload
}

export interface GameSyncingWorkerScoreGameMessage {
  type: 'SCORE_GAME'
  gameID: string
}

export interface GameSyncingWorkerPublishGameMessage {
  type: 'PUBLISH_GAME'
  payload: GameToPublish
}

export interface GameSyncingWorkerChangeScoringRoleMessage {
  type: 'TAKEOVER_GAME'
  payload: {
    gameID: string
    role: ScoringRoles
  }
}

export interface GameSyncingWorkerGameUpdatedMessage {
  type: 'UPDATED_GAME'
  payload: {
    gameID: string
    date: Date
  }
}

export interface GameSyncingWorkerSessionCleanupMessage {
  type: 'SESSION_CLEANUP'
}

export type GameSyncingWorkerInputMessage =
  | GameSyncingWorkerInitLiveScoringEvent
  | GameSyncingWorkerAddEventsMessage
  | GameSyncingWorkerScoreGameMessage
  | GameSyncingWorkerPublishGameMessage
  | GameSyncingWorkerChangeScoringRoleMessage
  | GameSyncingWorkerGameUpdatedMessage
  | GameSyncingWorkerSessionCleanupMessage
  | GameSyncingWorkerHeartbeatEvent

export interface GameSyncingWorkerInitalisedEvent {
  type: 'INITIALISED'
}

export const eventsSyncedEvent = 'EVENTS_SYNCED'

export interface GameSyncingWorkerEventsSyncedNotification {
  type: typeof eventsSyncedEvent
  payload: {
    gameID: string
    events: SyncedGameEvents[]
    eventLogReplaced?: boolean
  }
}

export const liveGameOwnerNotificationEvent = 'LIVE_GAME_OWNER_NOTIFICATION'

export interface GameSyncingWorkerLiveGameOwnerNotification {
  type: typeof liveGameOwnerNotificationEvent
  payload: {
    gameID: string
    status: GameScoringStatus
    currentScoringRole?: ScoringRoles
    takeover?: boolean
  }
}

export const locallyScoredGameUpdateEvent = 'LOCALLY_SCORED_GAME_UPDATE'

export interface GameSyncingWorkerLocallyScoredGamesUpdate {
  type: typeof locallyScoredGameUpdateEvent
  payload: IdbLocallyScoredGame
}

export const publishedGameUpdateEvent = 'PUBLISHED_GAME_UPDATE'

export interface GameSyncingWorkerPublishedGamesUpdate {
  type: typeof publishedGameUpdateEvent
  payload: GameToPublish
}

export const liveGameConnectionStateEvent = 'LIVE_GAME_CONNECTION_STATE'

export interface GameSyncingWorkerLiveGameConnectionState {
  type: typeof liveGameConnectionStateEvent
  payload: {
    connected: boolean
  }
}

export const gameUpdatedInPlaymakerEvent = 'GAME_UPDATED_PLAYMAKER'

export interface GameSyncingWorkerGameUpdatedInPlaymakerEvent {
  type: typeof gameUpdatedInPlaymakerEvent
  payload: {
    gameID: string
  }
}

export const gameResetEvent = 'GAME_RESET'

export interface GameSyncingWorkerGameResetEvent {
  type: typeof gameResetEvent
  payload: {
    gameID: string
  }
}

export const heartbeatEvent = 'HEARTBEAT'

export interface GameSyncingWorkerHeartbeatEvent {
  type: typeof heartbeatEvent
}

export interface GameSyncingWorkerFaroLog {
  type: 'LOG'
  message: unknown[]
  options?: PushLogOptions
}

export type GameSyncingWorkerOutputMessage =
  | GameSyncingWorkerInitalisedEvent
  | GameSyncingWorkerEventsSyncedNotification
  | GameSyncingWorkerLiveGameOwnerNotification
  | GameSyncingWorkerLocallyScoredGamesUpdate
  | GameSyncingWorkerLiveGameConnectionState
  | GameSyncingWorkerPublishedGamesUpdate
  | GameSyncingWorkerGameUpdatedInPlaymakerEvent
  | GameSyncingWorkerFaroLog
  | GameSyncingWorkerGameResetEvent
  | GameSyncingWorkerHeartbeatEvent

export type GameSyncingWorkerNotifications =
  | GameSyncingWorkerLiveGameOwnerNotification
  | GameSyncingWorkerEventsSyncedNotification
  | GameSyncingWorkerLocallyScoredGamesUpdate
  | GameSyncingWorkerLiveGameConnectionState
  | GameSyncingWorkerPublishedGamesUpdate
  | GameSyncingWorkerGameUpdatedInPlaymakerEvent
  | GameSyncingWorkerGameResetEvent
