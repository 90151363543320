import {
  LogLevel,
  MetaSession,
  PushLogOptions,
  faro,
} from '@grafana/faro-react'
import * as Sentry from '@sentry/react'

export const pushLog = (message: unknown[], options?: PushLogOptions): void => {
  if (!process.env.REACT_APP_GRAFANA_FARO_URL) {
    return
  }

  faro.api.pushLog([message], options)

  if (options?.level === LogLevel.ERROR || options?.level === LogLevel.WARN) {
    const err = new Error(JSON.stringify(options))
    err.name = JSON.stringify(message)
    Sentry.captureException(err)
  }
}

export const setSession = (session: MetaSession): void => {
  if (!process.env.REACT_APP_GRAFANA_FARO_URL) {
    return
  }

  faro.api.setSession(session)

  Sentry.setContext('session', session)
}

export const resetSession = (): void => {
  if (!process.env.REACT_APP_GRAFANA_FARO_URL) {
    return
  }

  faro.api.resetSession()

  Sentry.setContext('session', null)
}

export {
  type BaseObject,
  LogLevel,
  type PushLogOptions,
} from '@grafana/faro-react'
