declare global {
  interface Window {
    ReactNativeWebView?: {
      injectedObjectJson?: () => string | undefined
      postMessage?: (message: string) => void
    }
  }
}

export interface EscoringSessionState {
  token: string
  username: string
  // Field below are deprecated and will be removed when OutswingSession.tsx is cleaned up
  date?: string
  venueId?: string
  courtId?: string
  gameId?: string
}

export interface ReactNativeWebViewObject {
  escoringSessionState?: EscoringSessionState
}

const isWebviewTrueState = 'true'

// Call this function to persist the isWebview flag when user navigates to a new page
export const setIsWebView = () => {
  if (window.ReactNativeWebView) {
    localStorage.setItem('isWebview', isWebviewTrueState)
  } else {
    localStorage.removeItem('isWebview')
  }
}

export const isWebview = !!(
  window.ReactNativeWebView ||
  localStorage.getItem('isWebview') === isWebviewTrueState
)

export const getReactNativeWebViewObject = () => {
  try {
    const storageString = window.ReactNativeWebView?.injectedObjectJson?.()
    const storageObject: ReactNativeWebViewObject | undefined = storageString
      ? JSON.parse(storageString)
      : undefined
    if (!storageObject || typeof storageObject !== 'object') {
      return undefined
    }
    return storageObject
  } catch (error) {
    console.error('error: ', error)
  }
}

export type ReactNativeWebViewMessage = {
  type?: 'EXIT_GAME'
  reason?:
    | 'ADMIN_OWNER'
    | 'CLOSE'
    | 'FINALISED'
    | 'INVALID_TEAM'
    | 'PUBLISHED'
    | 'SECONDARY_SCORER'
    | 'TAKEOVER'
    | 'TRANSFERRED'
}

export const postReactNativeWebViewMessage = (
  message: ReactNativeWebViewMessage,
) => {
  window.ReactNativeWebView?.postMessage?.(JSON.stringify(message))
}
