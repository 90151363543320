import { WindowLocation } from '@reach/router'

interface LocationParams {
  [key: string]: string
}

export const getLocationParams: (
  location: WindowLocation,
) => LocationParams = location => {
  const params = location.search?.slice(1).split('&') ?? []

  return params.reduce((paramsObj: LocationParams, param: string) => {
    const separatorIndex = param.indexOf('=')
    const currentParam = {
      name: param.slice(0, separatorIndex),
      value: param.slice(separatorIndex + 1),
    }

    paramsObj[currentParam.name] = currentParam.value
    return paramsObj
  }, {} as LocationParams)
}

export const encodeLocationParams = (params: {
  [key: string]: string | undefined
}): string => {
  const newParams = Object.entries(params)
    .map(([key, value]) => {
      const encodedValue =
        typeof value !== 'undefined' ? encodeURIComponent(value) : undefined
      return !!key && typeof encodedValue !== 'undefined'
        ? [key, encodedValue].join('=')
        : undefined
    })
    .filter(param => !!param)
    .join('&')

  return newParams ? `?${newParams}` : ''
}

export const isValidRedirect = (
  redirectTo: string,
  origin: string,
): boolean => {
  return (
    RegExp(/^\/[a-z-/]+$/).test(redirectTo) ||
    redirectTo?.startsWith(`${origin}/`)
  )
}
